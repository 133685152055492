// eslint-disable
import { ActiveRegionResponse, AtlasConfig } from '@mit/atlas-config/dist.browser'
import { AppConfig, JsonMapType, RegionalConfig } from '../types'
import { EventBus, getStage } from '../common'
import { AuthProvider } from './AuthProvider'

export class Region {
  private readonly appConfig: AppConfig
  constructor(appConfig: AppConfig) {
    this.appConfig = appConfig
  }

  public getActiveRegionConfig = (): RegionalConfig => {
    const regionConfig: ActiveRegionResponse = JSON.parse(sessionStorage.getItem('ActiveRegion') as string)
    if (regionConfig === null) {
      console.error('No Regional Config Loaded. Default to Primary Region')
      return (this.appConfig.regions[0] as JsonMapType) as RegionalConfig
    }

    const isPrimaryRegion: boolean = regionConfig.activeRegion === 'primary'

    // eslint-disable-next-line
    let config =
      this.appConfig.regions.length > 1
        ? this.appConfig.regions.filter((region: any) => (isPrimaryRegion ? region.isPrimaryRegion : !region.isPrimaryRegion))[0]
        : this.appConfig.regions[0]

    // escape for apps that have only primary region config
    if (this.appConfig.regions.length === 1 && !isPrimaryRegion) {
      return (config as JsonMapType) as RegionalConfig
    }

    config.amplify.oauth.domain = regionConfig.authenticationUrl.replace('https://', '')
    config.api.domain = regionConfig.apiUrl.replace('https://', '')
    return (config as JsonMapType) as RegionalConfig
  }

  public initializeActiveRegion = async (useCognitoAuthentication: boolean): Promise<void> => {
    const config: ActiveRegionResponse = await this.getActiveRegion()
    const activeRegion = JSON.stringify(config)
    const previousActiveRegion = sessionStorage.getItem('ActiveRegion')

    sessionStorage.setItem('ActiveRegion', activeRegion)

    const authProvider = new AuthProvider(this.getActiveRegionConfig())

    if (useCognitoAuthentication) {
      await authProvider.configure()
      if (previousActiveRegion !== activeRegion) {
        // Active Region Has Changes. Check If First Load
        if (previousActiveRegion === null && config.activeRegion === 'primary') {
          // First Load, Primary Region Active
        } else {
          // Region Is Different, Refresh Token For New Region
          await authProvider.refreshToken()
        }
      }

      await authProvider.getToken(true)
    }

    EventBus.dispatch('CHECK_REGION_UPDATE', { config })
  }

  public getActiveRegion = async (): Promise<ActiveRegionResponse> => {
    const config: ActiveRegionResponse = await AtlasConfig.forRuntime().stage(getStage(this.appConfig)).queryWithHttp().build().queryActiveRegion()
    return config
  }
}
